/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/theme-variables";

// 3rd party libraries
//@import "~bootstrap/scss/bootstrap";
@import "bootstrap/scss/bootstrap";

@import "ngx-tagify/styles/tagify";
@import "ngx-toastr/toastr";

// Theme customization
@import "theme/theme";
@import "theme/dete-portal-branding";
@import "theme/csb-overlay";

html,
body {
  font-family: "Segoe UI", sans-serif;
}

.btn {
  border-radius: 0;
}

.btn-search {
  margin-top: 5px;
}

.btn-reset-context {
  color: #fff !important;
}

#export-buttons {
  float: right;
}

.small-icon {
  height: 16px !important;
  width: 16px !important;
}

.toast-container .ngx-toastr {
  padding: 15px;
}

.toastr {
  border-radius: 3px !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5) !important;
  color: #000 !important;
  font-size: 18px !important;
  text-align: center;
  top: 10px;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.8) !important;
}

.toastr-success {
  width: 40% !important;
  background: linear-gradient(0, #e9ecef 0%, #dee2e6 100%) no-repeat !important;
}

.toastr-info {
  top: 0;
  color: #fff !important;
  background-color: #7fb8e2;
}

.toastr-error {
  width: 40% !important;
  background: linear-gradient(0, #ee5f5b 0%, #c43c35 100%) no-repeat !important;
  color: #fff !important;
}

.table-striped-row-color {
  background-color: #f9f9f9 !important;
}

/* begin validation styles */
.invalid-input,
input.ng-invalid.ng-dirty {
  border: 1px solid red;
}

.invalid-input-message {
  color: #737373;
}

/* end validation styles */

/* begin querybox styles */
.query-parse-error {
  background-color: #a55;
  margin: 5px 0;
  padding: 5px;
  color: #fff;
}

.querybox {
  width: 100%;
}

.querybox-group {
  margin-bottom: 5px;
}

.querybox input,
.querybox div {
  border-radius: 0;
}
// end querybox styles

//begin simple query builder styles
.form-multiline .form-group {
  margin-bottom: 5px;
  margin-right: 30px;
}

.form-multiline .control-label {
  display: block;
  margin-bottom: 5px;
}

.form-control {
  border-radius: 0;
}

.control-label {
  text-align: left;
  font-weight: bold;
}

.login-text {
  text-align: center;
  display: inline-block;
  max-width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}

.addresses .tags .tag-item {
  background: #0072c6;
  color: #fff;
}

.addresses .tags .tag-item .remove-button {
  color: #fff;
}

tags-input .tags .input.invalid-tag {
  color: inherit;
}

@media (min-width: 768px) {
  .cc-input {
    width: 80px !important;
  }

  .lg-input {
    width: 100% !important;
  }
}
//end simple query builder styles

//start fts query styles
.fts-input {
  width: 100% !important;
}
// end fts query styles

// search results
.metadata-list {
  padding-left: 0;
}

.metadata-list li {
  list-style-type: none;
}

.search-results-controls {
  padding-top: 10px;
}

.search-results-controls #table-count {
  padding-top: 10px;
}

.search-results-data .ng-table th {
  text-align: left;
}

.no-results {
  padding-top: 30px;
  text-align: center;
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.pager li > a,
.pager li > span {
  border-radius: 0;
}
//search results

//begin view email content styles
.view-email-container {
  height: 400px;
}

.view-email-container textarea {
  height: 100%;
  width: 100%;
}

.email-content-view {
  border: none;
  width: 100%;
}
//end view email content styles

.no-items-msg {
  font-weight: bold;
  text-align: center;
  width: 100%;
}

.fts-highlight {
  background-color: #fff8dc !important;
  border-left: 1px #ddd solid;
  font-size: 0.9em;
}

.fts-highlight em {
  font-weight: bold;
}

.fts-no-highlight {
  background-color: #fff !important;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

// begin job styles
.jobs {
  border: 1px solid lightgrey;
  margin-top: 15px;
  padding: 10px;
  position: relative;
}

.jobs-detail-table {
  margin-bottom: 0;
  min-width: 25%;
  width: auto;
}

.jobs-detail-table > tbody > tr > td {
  border-bottom-width: 0;
  border-top: 0;
  padding: 2px 20px 2px 0;
}

.jobs-detail-table > tbody > tr > td:first-child {
  font-weight: bold;
}

.jobs .progress {
  margin-bottom: 0;
  width: 200px;
}

.export-button {
  margin-left: 5px;
}

.refresh-btn {
  color: #fff !important;
  cursor: pointer;
}

/************************************************
  Modals
*************************************************/

.modal-content {
  border: none;
}

.modal-footer {
  border-top: none;
  flex-wrap: nowrap;
}

.close {
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.2;
}

button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
}

// unauthorised message style
.message-screen {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

// menu styles

.build-info {
  font-size: 10px;
  padding-right: 10px;
}

/* end menu styles */

/* common style for page headers */
.page-title {
  align-items: center;
  background-color: #0072c6;
  color: #fff;
  display: flex !important;
  font-size: 18px;
  justify-content: space-between !important;
  margin-top: 0;
  margin-bottom: 10px;
  padding: 10px 15px;
}

.page-title-context {
  font-size: 14px;
  font-style: italic;
}

#main-container {
  margin-left: 15px;
  margin-right: 15px;
  min-height: 480px;
}

#working {
  display: none;
  position: fixed;
  transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  z-index: 100000;
  top: 0;
  left: 45%;
  opacity: 1;
  color: #fff;
  background-color: #7fb8e2;
  padding: 7px 25px;
  text-align: center;
  border: 1px solid #0072c6;
}

.footer {
  padding-top: 19px;
  color: #777;
  border-top: 1px solid #e5e5e5;
}

/* responsive table */
@media only screen and (max-width: 768px) {
  //Force table to not be like tables anymore
  #no-more-tables table,
  #no-more-tables thead,
  #no-more-tables tbody,
  #no-more-tables th,
  #no-more-tables td,
  #no-more-tables tr {
    display: block;
  }

  // Hide table headers (but not display: none;, for accessibility)
  #no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  #no-more-tables tr {
    border: 1px solid #ccc;
  }

  #no-more-tables td {
    // Behave  like a "row"
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
    white-space: normal;
    text-align: left;
  }

  #no-more-tables td::before {
    // Now like a table header
    position: absolute;
    // Top/left values mimic padding
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }

  //
  // Label the data
  //
  #no-more-tables td::before {
    content: attr(data-title);
  }

  .form-multiline .form-group {
    margin-bottom: 5px;
    margin-right: 0;
  }
}
